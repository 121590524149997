exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aesthetics-js": () => import("./../../../src/pages/aesthetics.js" /* webpackChunkName: "component---src-pages-aesthetics-js" */),
  "component---src-pages-blog-post-blog-post-slug-js": () => import("./../../../src/pages/blogPost/{blogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-post-blog-post-slug-js" */),
  "component---src-pages-blog-posts-js": () => import("./../../../src/pages/blogPosts.js" /* webpackChunkName: "component---src-pages-blog-posts-js" */),
  "component---src-pages-contentful-aesthetics-services-slug-js": () => import("./../../../src/pages/{ContentfulAestheticsServices.slug}.js" /* webpackChunkName: "component---src-pages-contentful-aesthetics-services-slug-js" */),
  "component---src-pages-dietetics-js": () => import("./../../../src/pages/dietetics.js" /* webpackChunkName: "component---src-pages-dietetics-js" */),
  "component---src-pages-endokrinolog-js": () => import("./../../../src/pages/endokrinolog.js" /* webpackChunkName: "component---src-pages-endokrinolog-js" */),
  "component---src-pages-gynecology-js": () => import("./../../../src/pages/gynecology.js" /* webpackChunkName: "component---src-pages-gynecology-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monalisatouch-js": () => import("./../../../src/pages/monalisatouch.js" /* webpackChunkName: "component---src-pages-monalisatouch-js" */),
  "component---src-pages-obstetrics-js": () => import("./../../../src/pages/obstetrics.js" /* webpackChunkName: "component---src-pages-obstetrics-js" */),
  "component---src-pages-osteoporosis-js": () => import("./../../../src/pages/osteoporosis.js" /* webpackChunkName: "component---src-pages-osteoporosis-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-team-contentful-terziiski-doctors-title-slug-js": () => import("./../../../src/pages/team/{contentfulTerziiskiDoctors.titleSlug}.js" /* webpackChunkName: "component---src-pages-team-contentful-terziiski-doctors-title-slug-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

