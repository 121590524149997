
import React from 'react';
import { WebSiteHeightProvider } from './src/context';

export const wrapRootElement = ({ element }) => (
  <WebSiteHeightProvider>{element}</WebSiteHeightProvider>
);

// in gatsby-browser.js
export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName('body')[0]
  body.scrollTop = 0
  return false
}