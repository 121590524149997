import React, { createContext, useState } from 'react';

const WebSiteHeight = createContext();

const WebSiteHeightProvider = ({ children }) => {
    const [websiteHeight, setWebsiteHeight] = useState(0);
    const [websiteWidth, setWebsiteWidth] = useState(0)
    return (
        <WebSiteHeight.Provider value={{ websiteHeight, setWebsiteHeight, websiteWidth, setWebsiteWidth }}>
            {children}
        </WebSiteHeight.Provider>
    );
};

export { WebSiteHeight, WebSiteHeightProvider };
